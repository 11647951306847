import React, { Component } from "react";
import "./App.css";
import classNames from "classnames";
import Fullscreen from "react-full-screen";
import { BsFullscreen } from "react-icons/bs"; //Icon
let slides = require("./data.json"); // using require instead of import

class App extends Component {
  render() {
    return (
      <div className="App">
        <CitiesSlider slides={slides} />
      </div>
    );
  }
}

//TODO:Needs to be used or cleaned up
// class HalfToneSlide extends React.Component {
//   render() {
//     return (
//       <header className="App-header">
//         <div className="quoter halftone">
//           <img src={this.author[1].backgroundImage} />
//         </div>
//         <div className="authorInfo">
//           <h2>{this.author[1].name}</h2>
//           <div className="avatar">
//             <img src={this.author[1].thumbnailImage} />
//           </div>
//           <div className="authorDescription"></div>
//           <div>5 January 1666</div>
//           <br />
//           <div>7 October 1708</div>
//         </div>

//         <div className="quote">
//           {this.author[1].quote.pb.map((i) => (
//             <div key={i}>{i}</div>
//           ))}
//         </div>
//       </header>
//     );
//   }
// }

class CitiesSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFull: false, // Fullscreen
      activeSlide: -1, //Slides
      prevSlide: -1,
      sliderReady: false,
      startAuthor: this.getRandomInt(slides.length),
    };
    this.IMAGE_PARTS = 4;
    this.changeTO = null;
    this.AUTOCHANGE_TIME = 30000;
  }

  goFull = () => {
    this.setState({ isFull: true });
  };

  componentWillUnmount() {
    window.clearTimeout(this.changeTO);
  }

  componentDidMount() {
    this.runAutochangeTO();
    setTimeout(() => {
      this.setState({ activeSlide: 0, sliderReady: true });
    }, 0);
  }

  runAutochangeTO() {
    this.changeTO = setTimeout(() => {
      this.changeSlides(1);
      this.runAutochangeTO();
    }, this.AUTOCHANGE_TIME);
  }

  changeSlides(change) {
    window.clearTimeout(this.changeTO);
    const { length } = this.props.slides;
    const prevSlide = this.state.activeSlide;
    let activeSlide = prevSlide + change;
    if (activeSlide < 0) activeSlide = length - 1;
    if (activeSlide >= length) activeSlide = 0;
    this.setState({ activeSlide, prevSlide });
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  // TODO: onarrowkeyChange

  render() {
    const { activeSlide, prevSlide, sliderReady } = this.state;
    // let imgAdd=  slide.img[this.getRandomInt(slide.img.length)]
    return (
      <div className={classNames("slider", { "s--ready": sliderReady })}>
        <button className={"fullscreenbutton"} onClick={this.goFull}>
          <BsFullscreen />
        </button>
        <Fullscreen
          enabled={this.state.isFull}
          onChange={(isFull) => this.setState({ isFull })}
        >
          <div className="full-screenable-node">
            <div className="slider__slides ">
              {this.props.slides.map((slide, index) => {
                console.log(slide);
                return (
                  <div
                    className={classNames("slider__slide", {
                      "s--active": activeSlide === index,
                      "s--prev": prevSlide === index,
                    })}
                    key={slide.quote}
                  >
                    <div className="slider__slide-content background-tint"  style={{
                        backgroundImage: `url(${slide.img[this.getRandomInt(slide.img.length)]})`,
                      }}>
                      <h2 key={slide.quote} className="slider__slide-heading">
                        {slide.quote[this.getRandomInt(slide.quote.length)]
                          .split(".")
                          .map((l) => (
                            <span>{l}</span>
                          ))}
                      </h2>
                      <h3
                        key={slide.author}
                        className="slider__slide-subheading"
                      >
                        - {slide.author} -
                      </h3>
                      <span
                        key={slide.lifeSpan}
                        className="slider__slide-subheading lifespan"
                      >
                        {slide.lifeSpan}
                      </span>
                      <span
                        key={slide.famousFor}
                        className="slider__slide-subheading famousfor"
                      >
                        {slide.tidbits && slide.tidbits.length > 0
                          ? slide.tidbits[
                              this.getRandomInt(slide.tidbits.length)
                            ]
                          : ""}
                      </span>
                    </div>
                    <div
                      className="slider__slide-parts"
                     
                    >
                      {/* {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => {
                        
                      return <div className="slider__slide-part" key={i}>
                        <div
                          className="slider__slide-part-inner"
                          style={{ backgroundImage: `url(${this.imgAdd})` }}
                        />
                      </div>
              })} */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fullscreen>
      </div>
    );
  }
}

export default App;
